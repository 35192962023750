<template>
  <div>
    <vx-card>
      <!-- Content Row -->
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full">
          <vx-card :noShadow="true" :cardBorder="true" title="Infos générales">
            <div class="vx-row">
              <div class="vx-col  w-full">
                <vs-input class="w-full mt-4" label="Nom Fournisseur" :value="Nom" @change.native="Nom=$event.target" name="nom" />
              </div>
              <div class="vx-col  w-full">
                <vs-input class="w-full mt-4" label="NumCC" :value="NumCC" @change.native="NumCC=$event.target.value" name="NumCC" />
              </div>
              <div class="vx-col  w-full">
                <vs-input class="w-full mt-4" label="NumCb" :value="NumCb" @change.native="NumCb=$event.target.value" name="NumCb" />
              </div>

              <div class="vx-col md:w-1/2 w-full">
                <div class="mt-4">
                  <label class="text-sm">Délais de livraison</label>
                  <flat-pickr v-model="DelaiLiv" class="w-full" name="DelaiLiv" />
                </div>
              </div>
              <div class="vx-col md:w-1/2 w-full">
                <div class="mt-4">
                  <label class="text-sm">Echéance</label>
                  <flat-pickr v-model="Echeance" class="w-full" name="Echeance" />
                </div>

              </div>
            </div>

            <vs-textarea class="w-full mt-4" counter="250" label="Remarque" :counter-danger.sync="counterDanger" :value="Remarque" @change.native="Remarque=$event.target.value" />

            <div class="demo-alignment">
              <span>Espèce:</span>
              <div class="flex">
                <vs-checkbox v-model="PaiEspece"></vs-checkbox>
              </div>

              <span>Chèque:</span>
              <div class="flex">
                <vs-checkbox v-model="PaiCheq"></vs-checkbox>
              </div>

              <span>Virement:</span>
              <div class="flex">
                <vs-checkbox v-model="PaiVirement"></vs-checkbox>
              </div>

              <span>Transfert:</span>
              <div class="flex">
                <vs-checkbox v-model="PaiTransfert"></vs-checkbox>
              </div>
            </div>

            <!-- Gender -->

          </vx-card>
        </div>

        <div class="vx-col md:w-1/2 w-full">
          <vx-card :noShadow="true" :cardBorder="true" title="Adresses">
          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full">
              <vs-input class="w-full mt-4" label="Adresse" v-model="adresse" name="Adresse" />

              <vs-input class="w-full mt-4" label="Telephone (*)" :value="Telephone" @change.native="Telephone=$event.target.value" v-validate="'required'" name="Telephone" />
              <span class="text-danger text-sm" v-show="errors.has('Telephone')">{{ errors.first('Telephone') }}</span>

              <vs-select autocomplete v-model="pays" class="w-full select-large mt-4" label="Pays">
                <vs-select-item :key="index" :value="item.pays" :text="item.pays" v-for="(item,index) in PaysData" class="w-full" />
              </vs-select>

              <vs-select autocomplete v-model="Quartier" class="w-full select-large mt-4" label="Quartier">
                <vs-select-item :key="index" :value="item.libelleQtier" :text="item.libelleQtier" v-for="(item,index) in QuartierData" class="w-full" />
              </vs-select>

              <vs-input class="w-full mt-4" label="Fax" v-model="Fax" type="text"  name="Fax" />
            </div>
            <div class="vx-col md:w-1/2 w-full">
              <vs-input class="w-full mt-4" label="Email (*)" :value="Email" @change.native="Email=$event.target.value" v-validate="'required'" name="Email" />
              <span class="text-danger text-sm" v-show="errors.has('Email')">{{ errors.first('Email') }}</span>

              <vs-input class="w-full mt-4" label="Mobile" :value="Mobile" @change.native="Mobile=$event.target.value" type="text"  name="Mobile" />

              <vs-select autocomplete v-model="ville" class="w-full select-large mt-4" label="Ville">
                <vs-select-item :key="index" :value="item.libelleVille" :text="item.libelleVille" v-for="(item,index) in VillesData" class="w-full" />
              </vs-select>

              <vs-input class="w-full mt-4" label="Localité" :value="localite" @change.native="localite=$event.target.value" type="text"  name="localite" />

              <vs-input class="w-full mt-4" label="Site" :value="web" @change.native="web=$event.target.value" type="text"  name="web" />
            </div>
          </div>


          </vx-card>


        </div>
      </div>
      <!-- Save & Reset Button -->
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <vs-button class="ml-auto mt-2" @click="editFournisseur">Modifier</vs-button>
            <vs-button class="ml-4 mt-2" type="border" color="warning" @click="annuler">Annuler</vs-button>
          </div>
        </div>
      </div>

    </vx-card>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  name: 'personnelNouveau',
  components: {
    vSelect,
    flatPickr
  },
  data () {
    return {
      IdUser: null,
      Nom: null,
      adresse: null,
      Telephone: null,
      pays: null,
      Quartier: null,
      Email: null,
      Mobile: null,
      ville: null,
      localite: null,
      Fax: null,
      web: null,
      NumCC: null,
      NumCb: null,
      DelaiLiv: null,
      Echeance: null,
      PaiEspece: false,
      PaiCheq: false,
      PaiVirement: false,
      PaiTransfert: false,
      Remarque: null,
      counterDanger: false
    }
  },
  computed: {
    PaysData () {
      return this.$store.state.pays.pays
    },
    VillesData () {
      return this.$store.state.pays.villes
    },
    QuartierData () {
      return this.$store.state.pays.quartiers
    }
  },
  methods: {
    editFournisseur () {
      const payload = {
        Nom: this.Nom,
        adresse: this.adresse,
        Telephone: this.Telephone,
        pays: this.pays,
        Quartier: this.Quartier,
        Email: this.Email,
        Mobile: this.Mobile,
        ville: this.ville,
        localite: this.localite,
        Fax: this.Fax,
        web: this.web,
        NumCC: this.NumCC,
        NumCb: this.NumCb,
        DelaiLiv: this.DelaiLiv,
        Echeance: this.Echeance,
        PaiEspece: this.PaiEspece,
        PaiCheq: this.PaiCheq,
        PaiVirement: this.PaiVirement,
        PaiTransfert: this.PaiTransfert,
        Remarque: this.Remarque,
        id: this.$route.params.fournisseurId
      }
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('fournisseur/updateFournisseur', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    annuler () {
      this.$router.push('/apps/fournisseur/fournisseur-view').catch(() => {})
    },
    getPays () {
      this.$store.dispatch('pays/getPays')
        .then(() => { })
        .catch((err) => { console.log(err) })
    },
    getVilles () {
      this.$store.dispatch('pays/getVilles')
        .then(() => { })
        .catch((err) => { console.log(err) })
    },
    getQuartiers () {
      this.$store.dispatch('pays/getQuartiers')
        .then(() => { })
        .catch((err) => { console.log(err) })
    },
    getFournisseur () {
      return this.$store.getters['fournisseur/getFournisseurById'](this.$route.params.fournisseurId)
    },
    getFournisseurs () {
      this.$store.dispatch('fournisseur/getFournisseurs')
        .then(() => {
          this.Nom = this.getFournisseur().Nom
          this.adresse = this.getFournisseur().Adresse.adresse
          this.Telephone = this.getFournisseur().Adresse.Telephone
          this.pays = this.getFournisseur().Adresse.pays
          this.Quartier = this.getFournisseur().Adresse.Quartier
          this.Email = this.getFournisseur().Adresse.Email
          this.Mobile = this.getFournisseur().Adresse.Mobile
          this.ville = this.getFournisseur().Adresse.ville
          this.localite = this.getFournisseur().Adresse.localite
          this.Fax = this.getFournisseur().Adresse.Fax
          this.web = this.getFournisseur().Adresse.web
          this.NumCC = this.getFournisseur().NumCC
          this.NumCb = this.getFournisseur().NumCb
          this.DelaiLiv = this.getFournisseur().DelaiLiv
          this.Echeance = this.getFournisseur().Echeance
          this.PaiEspece = this.getFournisseur().PaiEspece
          this.PaiCheq = this.getFournisseur().PaiCheq
          this.PaiVirement = this.getFournisseur().PaiVirement
          this.PaiTransfert = this.getFournisseur().PaiTransfert
          this.Remarque = this.getFournisseur().Remarque
        })
        .catch(err => { console.error(err) })
    }
  },
  created () {
    this.getFournisseurs()
    this.getPays()
    this.getVilles()
    this.getQuartiers()
  }
}
</script>

<style scoped>

</style>
